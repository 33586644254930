



























































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useGetters } from 'vuex-composition-helpers';

import { parseISO } from 'date-fns';
import { de } from 'date-fns/locale';

import { Operation } from '@/models/operation.model';
import { GET_OPERATIONS } from '@/store/operation.actions';

import OperationList from '@/components/OperationList.vue';

export default defineComponent({
  name: 'OperationListView',
  components: {
    OperationList
  },
  setup: () => {
    const getters = useGetters([GET_OPERATIONS]);
    const operations = getters[GET_OPERATIONS];

    const selectedYear = ref(new Date().getFullYear());
    const selectedMonth = ref(new Date().getMonth());
    const years = [] as number[];
    const months = [{ value: -1, text: 'Alle' }];
    const today = new Date();
    for (let i = 0; i < 4; ++i) {
      years.push(today.getFullYear() - i);
    }

    for (let i = 0; i < 12; ++i) {
      months.push({
        value: i,
        text: de.localize?.month(i, { width: 'abbreviated' })
      });
    }

    const now = new Date();
    const filteredOperations = computed(() => {
      return (operations.value as Operation[]).filter(op => {
        if (selectedYear.value == null && selectedMonth.value == null) {
          return true;
        }

        const dateStr = op.zeitstempel;
        if (!dateStr || dateStr.length === 0) {
          return false;
        }

        const date = parseISO(dateStr);
        const dateYear = date.getFullYear();
        const dateMonth = date.getMonth();
        if (selectedYear.value == null) {
          return (
            (selectedMonth.value === -1 || dateMonth === selectedMonth.value) &&
            now.getFullYear() === dateYear // aktuelles Jahr als Referenz
          );
        }

        return (
          (selectedMonth.value === -1 || dateMonth === selectedMonth.value) &&
          dateYear === selectedYear.value
        );
      });
    });

    return {
      filteredOperations,

      years,
      months,
      selectedYear,
      selectedMonth
    };
  }
});
